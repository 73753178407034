import { latLng2Tile } from 'google-map-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import zArrowGreen from '../../assets/icons/z-arrow-green.svg';
import zArrowRed from '../../assets/icons/z-arrow-red.svg';

interface IProps {
  icon: string;
  amount: string;
  title: string;
  changePercent: string;
  timePeriod: string;
  currency?: boolean;
}


export const AggregatesCard: React.FC<IProps> =
  ({ icon, amount, title, changePercent, timePeriod, currency }) => {
    const [_, i18next] = useTranslation();

    const numberChangePercent = Number(changePercent);
    changePercent = Number(changePercent ?? 0).toFixed(2);

    const currencyFormatOptions: Intl.NumberFormatOptions = { style: 'currency', currency: 'SAR', currencyDisplay: i18next.language == 'ar' ? 'name' : 'code' };
    const currencyFormatter = new Intl.NumberFormat(i18next.language, currencyFormatOptions);

    const styles = {
      icon: {
        width: '60px',
        height: '60px',
      },
      percentage: {
        color: numberChangePercent > 0 ? '#36B37E' : '#E22A1F',
      },
    };

    return (
      <div className="card container p-4 col">

        <div className="row gap-0 align-items-center">
          <div className="col-3" >
            <img style={styles.icon} src={icon} />
          </div>
          <div className="col h5 bold" >{currency ? currencyFormatter.format(Number(amount ?? '0')) : amount ?? 0}</div>
        </div>

        <div className="row row-cols-1 ">
          <div className="col text-muted h6">{title}</div>
        </div>
        <div className="row">
          <div className="col">{timePeriod}</div>
          <div className="col">
            <img style={i18next.language == 'ar' ? { transform: 'scaleX(-1)' } : {}} src={numberChangePercent > 0 ? zArrowGreen : zArrowRed} width="24px" height='24px' />
            <span className="col mx-1" style={styles.percentage}>
              {changePercent}%
            </span>

          </div>
        </div>
      </div>
    );
  }

