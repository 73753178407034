import React, { useEffect } from 'react';
import { AggregatesCard } from '../../components/AggregatesCard/AggregatesCard';

// TODO: Fix this import to work with webpack and typescript (@assets/icons/green_money.svg)
// or maybe use a global SVG loader component
import completedOrdersUrl  from '../../assets/icons/completed_orders.svg';
import pendingOrdersUrl from '../../assets/icons/pending_orders.svg';
import customerUrl from '../../assets/icons/customers.svg';
import { useTranslation } from 'react-i18next';
import { aggregatesAPI, IAggregatesData } from './hook/aggregatesApi';

export const Dashboard: React.FC = () => {
    const [t] = useTranslation();
    const [aggregates, setAggregates] = React.useState<IAggregatesData>(null);

    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);
    const endDate = new Date();


    useEffect(() => {

        aggregatesAPI(startDate.toISOString(), endDate.toISOString(), (success, data, error) => {
            if (success) {
                console.log(data);
                setAggregates(data);
            } else {
                console.log(error);
            }
        });

    }, []);
    


    return (
        <>
            <div className="row row-cols-4">

                <div className="col">
                    <AggregatesCard
                        icon={completedOrdersUrl}
                        amount={aggregates?.completedOrders}
                        currency={true}
                        title={t('dashboard.completed-orders')}
                        timePeriod={t('common.last-week')}
                        changePercent={aggregates?.completedOrdersRate} />
                </div>

                <div className="col">
                    <AggregatesCard
                        icon={pendingOrdersUrl}
                        amount={aggregates?.pendingOrders}
                        currency={true}
                        title={t('dashboard.pending-orders')}
                        timePeriod={t('common.last-week')}
                        changePercent={aggregates?.pendingOrdersRate} />
                </div>

                <div className="col">
                    <AggregatesCard
                        icon={customerUrl}
                        amount={aggregates?.customers}
                        title={t('common.customers')}
                        timePeriod={t('common.last-week')}
                        changePercent={aggregates?.customerRate} />
                </div>

            </div>

        </>
    );
}

