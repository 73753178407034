import { emailValidation } from "../../../../utils/Helpers";

export interface userLoginData {
    email: string;
    phone: string;
}

export const onChangePhone = (e: React.ChangeEvent<HTMLInputElement>, setPhone: React.Dispatch<React.SetStateAction<string>>) => {
    // validate the phone number
    if (isNaN(+e.target.value) || e.target.value.length >= 10 || e.target.value === "0") return;
    else setPhone(e.target.value)
}

export const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>, setEmail: React.Dispatch<React.SetStateAction<string>>) => {
    // validate the email address
    emailValidation(e.target.value) ? setEmail(e.target.value) : setEmail('')
}