import { Checkmark, Close } from "../../../../utils/Icons";

export interface userLoginData {
    email: string;
    phone: string;
    password?: string;
}

export interface validationItems {
    uppercase: boolean;
    lowercase: boolean;
    number: boolean;
    sign: boolean;
    length: boolean;
}

export const validationUL = (validation: validationItems): JSX.Element => {
    return (
        <ul className='d-inline-block p-0 list-inline'>
            <li style={{color: validation?.uppercase ? "#1F865A" : '' }}>
                {!validation?.uppercase ? <Close /> : <Checkmark />} At least 1 uppercase letter
            </li>
            <li style={{color: validation?.lowercase ? "#1F865A" : '' }}>{!validation?.lowercase ? <Close /> : <Checkmark />} At least 1 lowercase letter</li>
            <li style={{color: validation?.number ? "#1F865A" : '' }}>{!validation?.number ? <Close /> : <Checkmark />} At least 1 number</li>
            <li style={{color: validation?.length ? "#1F865A" : '' }}>{!validation?.length ? <Close /> : <Checkmark />} At least 8 char</li>
            <li style={{color: validation?.sign ? "#1F865A" : '' }}>{!validation?.sign ? <Close /> : <Checkmark />} At least 1 special symbol ({"`!@#$%^&*"})</li>
        </ul>
    );
}