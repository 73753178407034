import React from 'react';
import { useState } from 'react';
import { checkDataAPI } from './hook/API';
import background from "../../../assets/images/curved-6.jpg";
import { useTranslation } from 'react-i18next';
import { userLoginData, validationItems, validationUL } from './hook/ScreenHelper';
import { store } from '../../../redux/store';
import { setErrorMsg } from '../../../redux/reducers/settingsSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { Close, Error, LogoSmall } from '../../../utils/Icons';
import { hasLowerCase, hasNumbers, hasSpicialChar, hasUpperCase } from '../../../utils/Validation';

export const UpdatePassword: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const stateData = location.state as userLoginData;

    const [error, setError] = useState<boolean>(false);
    const [validation, setValidation] = useState<validationItems>();
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    
    const buttonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        checkDataAPI({email: stateData.email, phone: stateData.phone, password}, (success) => {
            if (success) { 
                navigate('/login')
            } else {
                store.dispatch(setErrorMsg({
                    icon: <Error />,
                    title: t('update_password.errors.merchant_not_found'),
                    message: t('update_password.errors.merchant_not_found_desc')
                }))
            }
        });
    };
    
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setPassword(val);
        setValidation({
            uppercase: hasUpperCase(val), 
            lowercase: hasLowerCase(val), 
            length: val.length >= 8, 
            number: hasNumbers(val), 
            sign: hasSpicialChar(val)
        });
        console.log(validation);
    }

    const onChangeRepeatPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setRepeatPassword(val);
        (val === password) ? setError(false) : setError(true);
    }


    return (
        <section>
            <div className="page-header min-vh-100">
                <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="oblique position-absolute top-0 h-100 d-md-block d-none me-n8" style={{ width: '30%' }}>
                            <div className="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" style={{ backgroundImage: `url(${background})` }}>
                            </div>
                            <img style={{width: '60%', margin: 'auto', position: 'absolute', inset: 0}} src={require('../../../assets/icons/logo-with-words.png')} alt="" />
                        </div>
                    </div>
                    <div className="col-md-8 d-flex flex-column mx-auto">
                        <div className="card card-plain mt-6">
                            <div className="card-header  d-flex justify-content-center d-md-none">
                                <LogoSmall width={"75%"} />
                            </div>
                            <div className="card-header pb-0 text-left bg-transparent">
                                <h3 className="fw-normal text-dark">{t("update_password.title")}</h3>
                                <h6 className="fw-normal text-dark">{t("update_password.description")}</h6>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 d-flex flex-column ">
                                        <div className='row'>
                                            <div className="col-md-12 d-flex flex-column ">
                                                <label>Password</label>
                                                <div className="mb-3 d-flex">
                                                    <input type="password" className={"form-control "} placeholder="Password" onChange={onChange} />
                                                </div>
                                            </div>
                                            <div className="col-md-12 d-flex flex-column ">
                                                <label>Repeat Password</label>
                                                <div className="mb-1 d-flex">
                                                    <input type="password" className={"form-control " + (error ? 'is-invalid' : '')} placeholder="Repeat Password" onChange={onChangeRepeatPassword} />
                                                </div>
                                                {error && <div className="invalid-feedback d-block">Passwords don’t match, try again.</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 d-flex flex-column ">
                                        <div  className='m-auto d-grid'>
                                            <p className='d-inline'>Password should contain:</p>
                                            {validationUL(validation)}
                                        </div>
                                    </div>
                                    <div className="text-center d-flex flex-column flex-md-row mt-4 justify-content-between">
                                        <a href="login" className="btn btn-secondary px-7 py-3">Go back</a>
                                        <button type="button" className="btn btn-warning px-7 py-3" disabled={
                                            !validation?.uppercase ||
                                            !validation?.lowercase ||
                                            !validation?.length ||
                                            !validation?.number ||
                                            !validation?.sign ||
                                            error
                                        } onClick={buttonHandler}>Continue</button>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
                </div>
            </div>
        </section>
    );
}

