import React, { useEffect } from 'react';
import { Main } from '../routers/Main.router';
import { Sidebar } from './Sidebar/Sidebar';
import { RightSidebar } from './RightSidebar/RightSidebar';
import { Navbar } from './Navbar/Navbar';
import { Footer } from './Footer/Footer';
import { useTranslation } from 'react-i18next';

export const MainLayout: React.FC = () => {
    const { i18n } = useTranslation();

    useEffect(() => {
        document.body.className = (i18n.language === "en") ? 'g-sidenav-show bg-gray-100 ltr' : 'g-sidenav-show bg-gray-100 rtl';
    }, [])
    return (
        <>
            <div className="row">
                <Sidebar />
                <div className="main-content position-relative bg-gray-100 max-height-vh-100 h-100">
                    <Navbar />
                    <div className="container-fluid py-4">
                        <Main />
                        <Footer />
                    </div>
                </div>
                <RightSidebar />
            </div>
        </>
    );
}

