import API from '../../../../hooks/api';
import { stateType } from './ScreenHelper';



export const getInfoOTPAPI = async (
    params: stateType, 
    callBack: (success: boolean, errorMsg: string | null) => void = () => {}
) => {
await API.get("/connect/merchant/reset/otp/info", {params})
    .then((res) => {
        console.log(res)
        callBack(true, null);
    }).catch((err) => {
        callBack(false, err);
    });
}

export const verifyOTPAPI = async (
        email: string,
        phone: string,
        otp: string,
        callBack: (success: boolean, errorMsg: string | null) => void = () => {}
    ) => {
    await API.post("/connect/merchant/reset/otp/verify", { email, otp, phone })
    .then((res) => {
        res.data.result === "true" ? callBack(true, null) : callBack(false, res.data.result);
    }).catch((err) => {
        callBack(false, err);
    });
}


export const resendOTPAPI = async (
    params: stateType, 
    callBack: (success: boolean, errorMsg: string | null) => void = () => {}
) => {
await API.post("/connect/merchant/reset/otp/resend", params)
.then((res) => {
    console.log(res)
    callBack(true, null);
}).catch((err) => {
    callBack(false, err);
});
}
