/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getInfoOTPAPI, resendOTPAPI, verifyOTPAPI } from './hook/API';
import background from "../../../assets/images/curved-6.jpg";
import { useTranslation } from 'react-i18next';
import { DEFAULT_OTPCODES, onKeyUpHandler, OTPCodes, OTPCodesRef, showBlockedAlert, showWrongOTPAlert, stateType } from './hook/ScreenHelper';
import { Error, LogoSmall } from '../../../utils/Icons';

export const OTP: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const stateData = location.state as stateType;

    const [otp, setOtp] = useState<OTPCodes>(DEFAULT_OTPCODES);
    const [ref] = useState<OTPCodesRef>({otp_1: useRef(null), otp_2: useRef(null), otp_3: useRef(null), otp_4: useRef(null)});
    const [counter, setCounter] = React.useState(60);

    useEffect(() => {
        // call api to get otp info
        getInfoOTPAPI(stateData, (success) => {
            if (success) {
                ref.otp_1.current.focus();
            }
        });
    }, []);

    React.useEffect(() => {
        const timer =
          counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    const submitButtonHandler = (stateData: stateType) => {
        // get the value of otp from refs
        const otpValue: string = ref.otp_1.current.value + ref.otp_2.current.value + ref.otp_3.current.value + ref.otp_4.current.value;
        // call api to verify otp
        verifyOTPAPI(stateData.email, stateData.phone, otpValue, (success, errorMsg) => {
            if (success) {
                navigate('/forget_password/update_password', {state: {stateData} })
            } else {
                if (errorMsg === "blocked")
                    showBlockedAlert(<Error />, t);
                else if (errorMsg === "false")
                    showWrongOTPAlert(<Error />, t);
            }
        });
    };

    const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => 
        onKeyUpHandler(e, otp, setOtp, ref)

    const resend = (e: React.MouseEvent<HTMLButtonElement>) => {
        setCounter(60);
        resendOTPAPI(stateData, (success) => {
            if (success) {
                ref.otp_1.current.focus();
            }
        });
    }

    return (
        <section>
            <div className="page-header min-vh-100">
                <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        
                        <div className="oblique position-absolute top-0 h-100 d-md-block d-none me-n8" style={{ width: '30%' }}>
                            <div className="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" style={{ backgroundImage: `url(${background})` }}>
                            </div>
                            <img style={{width: '60%', margin: 'auto', position: 'absolute', inset: 0}} src={require('../../../assets/icons/logo-with-words.png')} alt="" />
                        </div>
                    </div>
                    <div className="col-md-8 d-flex flex-column mx-auto">
                        <div className="card card-plain mt-6">
                            <div className="card-header  d-flex justify-content-center d-md-none">
                                <LogoSmall width={"75%"} />
                            </div>
                            <div className="card-header pb-0 text-left bg-transparent">
                                <h3 className="fw-normal text-dark">{t("otp.title")}</h3>
                                <h6 className="fw-normal text-dark">{t("otp.description")}</h6>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 d-flex flex-column ">
                                        <div className="mb-3 mt-5 d-flex">
                                            <input ref={ref.otp_1} type="text" className="form-control me-2" name="OTP1" placeholder="OTP1" value={otp.otp_1} onKeyUp={onKeyUp}/>
                                            <input ref={ref.otp_2} type="text" className="form-control me-2" name="OTP2" placeholder="OTP2" value={otp.otp_2} onKeyUp={onKeyUp}/>
                                            <input ref={ref.otp_3} type="text" className="form-control me-2" name="OTP3" placeholder="OTP3" value={otp.otp_3} onKeyUp={onKeyUp}/>
                                            <input ref={ref.otp_4} type="text" className="form-control me-2" name="OTP4" placeholder="OTP4" value={otp.otp_4} onKeyUp={onKeyUp}/>
                                        </div>
                                    </div>
                                    <div className="col-md-12 d-flex flex-row align-items-center">
                                        {counter === 0 && (
                                            <>
                                                <p><small>{t("otp.resend_msg")}</small></p>
                                                <button type="button" className="btn btn-link" onClick={resend}>{t("inputs.resend")}</button>
                                            </>
                                        )}
                                        {counter > 0 && <p><small>{t("otp.resend_in") + " " + counter}</small></p>}
                                    </div>
                                    <div className="text-center d-flex flex-column flex-md-row mt-4 justify-content-between">
                                        <a href="login" className="btn btn-secondary px-7 py-3">Go back</a>
                                        <button type="button" className="btn btn-warning px-7 py-3" onClick={() => submitButtonHandler(stateData)}>Continue</button>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
                </div>
            </div>
        </section>
    );
}

