import React from 'react';
import { useState } from 'react';
import { loginAPI } from './hook/loginApi';
import background from "../../assets/images/curved-6.jpg";
import { useTranslation } from 'react-i18next';
import { store } from '../../redux/store';
import { setErrorMsg } from '../../redux/reducers/settingsSlice';
import { Error, LogoSmall } from '../../utils/Icons';

export const Login: React.FC = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    
    const buttonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        loginAPI({email: email, password: password}, (success) => {
            if (success) {
                console.log('refresh');
                window.location.reload();
            } else 
                store.dispatch(setErrorMsg({
                    icon: <Error />,
                    title: t('reset_password.errors.merchant_not_found'),
                    message: t('reset_password.errors.merchant_not_found_desc')
                }))
        });
    };
    return (
        <section>
            <div className="page-header min-vh-100">
                <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="oblique position-absolute top-0 h-100 d-md-block d-none me-n8" style={{ width: '30%' }}>
                            <div className="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" style={{ backgroundImage: `url(${background})` }}>
                            </div>
                            <img style={{width: '20%', margin: 'auto', position: 'absolute', inset: 0}} src={require('../../assets/icons/logo-with-words.png')} alt="" />
                        </div>
                    </div>
                    <div className="col-md-8 d-flex flex-column mx-auto">
                        <div className="card card-plain mt-6">
                            <div className="card-header  d-flex justify-content-center d-md-none">
                                <LogoSmall width={"75%"} />
                            </div>
                            <div className="card-header pb-0 text-left bg-transparent d-flex justify-content-between align-items-center">
                                <h3 className="fw-normal text-dark d-inline-block">{t("login.title")}</h3>
                                <a href="create_account" className="btn btn-dark">Create Account</a>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 d-flex flex-column mx-auto">
                                        <label>Email</label>
                                        <div className="mb-3">
                                            <input type="email" className="form-control" placeholder="Email" aria-label="Email" aria-describedby="email-addon" onChange={e => setEmail(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 d-flex flex-column mx-auto">
                                        <label>Password</label>
                                        <div className="mb-3">
                                            <input type="password" className="form-control" placeholder="Password" aria-label="Password" aria-describedby="password-addon" onChange={e => setPassword(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="text-center d-flex flex-column flex-md-row mt-4 justify-content-between">
                                        <p className="mb-4 text-sm ">
                                            <a href="forget_password" className="text-warning  font-weight-bold"> Forget password?</a>
                                        </p>
                                        <button type="button" className="btn btn-warning px-7 py-3" onClick={buttonHandler}>Log in</button>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
                </div>
            </div>
        </section>
    );
}

