/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const RightSidebar: React.FC = () => {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState<boolean>();

  useEffect(() =>  setLang(i18n.language === 'en' ? true : false), []);
  const handleOnChangeLang = () => {
    setLang(!lang);
  };
  return (
      <>
          <div className="fixed-plugin">
          <a className="fixed-plugin-button text-dark position-fixed px-3 py-2">
            <i className="fa fa-cog py-2"> </i>
          </a>
          <div className="card shadow-lg ">
            <div className="card-header pb-0 pt-3 ">
              <div className="float-start">
                <h5 className="mt-3 mb-0">Dashbaord Configuration</h5>
                <p>See our dashboard options.</p>
              </div>
              <div className="float-end mt-4">
                <button className="btn btn-link text-dark p-0 fixed-plugin-close-button">
                  <i className="fa fa-close"></i>
                </button>
              </div>
              {/* <!-- End Toggle Button --> */}
            </div>
            <hr className="horizontal dark my-1"/>
            <div className="card-body pt-sm-3 pt-0">
              {/* <!-- Sidebar Backgrounds --> */}
              <div>
                <h6 className="mb-0">Dashbaord Language</h6>
              </div>
              <div className="form-check form-switch ps-0">
                <input 
                  className="form-check-input mt-1 ms-auto" 
                  type="checkbox" 
                  id="navbarFixed" 
                  checked={lang} 
                  onChange={handleOnChangeLang}
                />
              </div>
              
            
              {/* <!-- Sidenav Type --> */}
              <div className="mt-3">
                <h6 className="mb-0">Sidenav Type</h6>
                <p className="text-sm">Choose between 2 different sidenav types.</p>
              </div>
              
              <p className="text-sm d-xl-none d-block mt-2">You can change the sidenav type just on desktop view.</p>
              {/* <!-- Navbar Fixed --> */}
              <div className="mt-3">
                <h6 className="mb-0">Navbar Fixed</h6>
              </div>
              <div className="form-check form-switch ps-0">
                <input className="form-check-input mt-1 ms-auto" type="checkbox" id="navbarFixed" />
              </div>
              <hr className="horizontal dark my-sm-4"/>
              <a className="btn bg-gradient-dark w-100" href="https://www.creative-tim.com/product/soft-ui-dashboard">Free Download</a>
              <a className="btn btn-outline-dark w-100" href="https://www.creative-tim.com/learning-lab/bootstrap/license/soft-ui-dashboard">View documentation</a>
              
            </div>
          </div>
        </div>
      </>
  );
}

