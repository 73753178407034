import { createRef, useRef } from "react";
import { setErrorMsg } from "../../../../redux/reducers/settingsSlice";
import { store } from "../../../../redux/store";
import { verifyOTPAPI } from "./API";

export interface stateType {
    email: string;
    phone: string;
 }

export interface OTPCodes {
    otp_1: string;
    otp_2: string;
    otp_3: string;
    otp_4: string;
}

export const DEFAULT_OTPCODES: OTPCodes= {otp_1: '', otp_2: '', otp_3: '', otp_4: ''}

export interface OTPCodesRef {
    otp_1: React.MutableRefObject<any>;
    otp_2: React.MutableRefObject<any>;
    otp_3: React.MutableRefObject<any>;
    otp_4: React.MutableRefObject<any>;
}

export const onKeyUpHandler = (e: React.KeyboardEvent<HTMLInputElement>, otp, setOtp, otpRefs: OTPCodesRef, submitOtp: () => void = () => {}) => {
    // init the values 
    const name = (e.target as HTMLInputElement).name;
    let value = e.key.slice(-1);

    // check which key user pressed 
    if (e.key === "Backspace") {
        value = "";
    } else {
        // make sure input type to be a number 
        if (isNaN(parseInt(e.key.slice(-1)))) return;
    }
    
    switch (name) {
        case "OTP1":
            setOtp({...otp, otp_1: value})
            if (value !== '') otpRefs.otp_2.current.focus();
            break;
        case "OTP2":
            setOtp({...otp, otp_2: value});
            (value === '') ?  otpRefs.otp_1.current.focus() : otpRefs.otp_3.current.focus();
            break;
        case "OTP3":
            setOtp({...otp, otp_3: value});
            (value === '') ?  otpRefs.otp_2.current.focus() : otpRefs.otp_4.current.focus();
            break;
        case "OTP4":
            setOtp({...otp, otp_4: value});
            (value === '') ?  otpRefs.otp_3.current.focus() : submitOtp();
            break;
        default:
            break;
    }
}

export const showBlockedAlert = (icon: JSX.Element, t) => {
    store.dispatch(setErrorMsg({
        icon: icon,
        title: t('reset_password.errors.merchant_not_found'),
        message: t('reset_password.errors.merchant_not_found_desc')
    }))
}

export const showWrongOTPAlert = (icon: JSX.Element, t) => {
    store.dispatch(setErrorMsg({
        icon: icon,
        title: t('otp.errors.wrong_otp'),
        message: t('otp.errors.wrong_otp_description')
    }))
}