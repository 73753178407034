import API from '../../../hooks/api';
import { userLoginData } from './ScreenHelper';

export const registerAPI = async (
        data: userLoginData,
        callBack: (success: boolean, errorMsg: string | null) => void = () => {}
    ) => {
    await API.post("/order/merchant/contactInfo", data)
    .then((res) => {
        console.log(res)
        callBack(true, null);
    }).catch((err) => {
        callBack(false, err);
    });
}
