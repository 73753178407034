/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';

export const Navbar: React.FC = () => {
    const [t, i18n] = useTranslation();

    const toggleLanguage = () => {
        if (i18n.language === 'en') {
            i18n.changeLanguage('ar');
        } else {
            i18n.changeLanguage('en');
        }
    }

    return (
        <>
            {/* <!-- Navbar --> */}
            <nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" navbar-scroll="true">
                <div className="container-fluid py-4 px-3">
                    <div className="d-flex flex-row-reverse">
                        <div onClick={toggleLanguage} className="p-2" style={i18n.language === 'en' ? {fontWeight:'bold'} : {}}>English</div>
                        <div onClick={toggleLanguage} className="p-2" style={i18n.language === 'ar' ? {fontWeight:'bold'} : {}}>عربي</div>
                    </div>
                </div>
            </nav>
            {/* <!-- End Navbar --> */}
        </>
    );
}

