import React, { useState } from 'react';
import { registerAPI } from './hook/API';
import background from "../../assets/images/curved-6.jpg";
import { useTranslation } from 'react-i18next';
import { DEFAULT_DATA, userLoginData } from './hook/ScreenHelper';
import { store } from '../../redux/store';
import { setErrorMsg } from '../../redux/reducers/settingsSlice';
import { Error, LogoSmall } from '../../utils/Icons';
import { emailValidation } from '../../utils/Helpers';

export const CreateAccount: React.FC = () => {
    const { t } = useTranslation();
    const [values, setValues] = useState<userLoginData>(DEFAULT_DATA);
    
    const buttonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (emailValidation(values.contactEmail)) {
            registerAPI(values, (success) => {
                if (!success) {
                    store.dispatch(setErrorMsg({
                        icon: <Error />,
                        title: t('errors.something_went_wrong'),
                    }))
                }
            });
        } else {
            store.dispatch(setErrorMsg({
                icon: <Error />,
                title: t('errors.invalid_email'),
            }))
        }
        
    };

    return (
        <section>
            <div className="page-header min-vh-100">
                <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="oblique position-absolute top-0 h-100 d-md-block d-none me-n8" style={{ width: '30%' }}>
                            <div className="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6" style={{ backgroundImage: `url(${background})` }}>
                            </div>
                            <img style={{width: '60%', margin: 'auto', position: 'absolute', inset: 0}} src={require('../../assets/icons/logo-with-words.png')} alt="" />
                        </div>
                    </div>
                    <div className="col-md-8 d-flex flex-column mx-auto">
                        <div className="card card-plain mt-6">
                            <div className="card-header  d-flex justify-content-center d-md-none">
                                <LogoSmall width={"75%"} />
                            </div>
                            <div className="card-header pb-0 text-left bg-transparent">
                                <h3 className="fw-normal text-dark">{t("create_account.title")}</h3>
                                <h6 className="fw-normal text-dark">{t("create_account.description")}</h6>

                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 d-flex flex-column ">
                                        <label>{t("create_account.inputs.business_name")}</label>
                                        <div className="mb-3 d-flex">
                                            <input type="text" className="form-control" placeholder={t("create_account.inputs.business_name_placeholder")} onChange={e => setValues({...values, businessName: e.target.value})} value={values.businessName}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 d-flex flex-column ">
                                        <label>{t("create_account.inputs.business_cr")}</label>
                                        <div className="mb-3 d-flex">
                                            <input type="text" className="form-control" placeholder={t("create_account.inputs.business_cr_placeholder")} onChange={e => setValues({...values, businessCr: e.target.value})} value={values.businessCr}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 d-flex flex-column ">
                                        <label>{t("create_account.inputs.business_contact_name")}</label>
                                        <div className="mb-3 d-flex">
                                            <input type="text" className="form-control" placeholder={t("create_account.inputs.business_contact_name_placeholder")} onChange={e => setValues({...values, contactName: e.target.value})} value={values.contactName}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 d-flex flex-column ">
                                        <label>{t("create_account.inputs.business_contact_phone")}</label>
                                        <div className="mb-3 d-flex">
                                            <input type="text" className="form-control" placeholder={t("create_account.inputs.business_contact_phone_placeholder")} onChange={e => setValues({...values, contactNumber: e.target.value})} value={values.contactNumber}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 d-flex flex-column ">
                                        <label>{t("create_account.inputs.business_contact_email")}</label>
                                        <div className="mb-3 d-flex">
                                            <input type="text" className="form-control" placeholder={t("create_account.inputs.business_contact_email_placeholder")} onChange={e => setValues({...values, contactEmail: e.target.value})} value={values.contactEmail}/>
                                        </div>
                                    </div>
                                    <div className="text-center d-flex flex-column flex-md-row mt-4 justify-content-between">
                                        <a href="login" className="btn btn-secondary px-7 py-3">{t("inputs.cancel")}</a>
                                        <button type="button" className="btn btn-warning px-7 py-3" onClick={buttonHandler}>{t("inputs.register")}</button>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
                </div>
            </div>
        </section>
    );
}

