import 'jquery/dist/jquery.min.js';

import { MainLayout } from './layouts/MainLayout';
import { BrowserRouter } from 'react-router-dom';
import { useEffect } from 'react';
import { localStorageHelper } from './hooks/localStorageHelper';
import { useAppSelector } from './hooks/hooks';
import { Loader } from './components/Loader/Loader';
import { AuthLayout } from './layouts/AuthLayout';
import { useTranslation } from 'react-i18next';
import { Alert } from './components/Alert/Alert';
import { store } from './redux/store';
import { setErrorMsg } from './redux/reducers/settingsSlice';


function App() {
  const isLoggedIn: string | undefined = localStorageHelper.load('profile')?.access_token;
  const {loading, error} = useAppSelector(state => state.settings);

  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();
  
  useEffect(()=> {
    const LoadExternalScript = () => {
      const externalScript = document.createElement("script");
      externalScript.id = "external_soft-ui-dashboard";
      externalScript.async = true;
      externalScript.type = "text/javascript";
      externalScript.setAttribute("crossorigin", "anonymous");
      document.body.appendChild(externalScript);
      externalScript.src = './js/soft-ui-dashboard.js';
    };
    if (!document.getElementById("external_soft-ui-dashboard")) {
      LoadExternalScript();
    }
  });
  
  return (
    <BrowserRouter>
      {loading ? <Loader /> : null}
      {!isLoggedIn ?
        <AuthLayout /> :
        <MainLayout />
      }
      <Alert
          show={error?.title != null}
          icon={error.icon == null ? null : error.icon}
          title={error?.title}
          errorMsg={error?.message}
          acceptBtnTitle={error?.acceptBtnTitle ?? "Exit"}
          acceptBtnOnClick={() => {
              if (typeof error?.acceptBtnAction !== "undefined") {
                error?.acceptBtnAction()
              }
              store.dispatch(setErrorMsg({title: null}))
          }}
          cancelBtnTitle={error?.cancelBtnTitle}
          cancelBtnOnClick={() => {
              if (typeof error?.cancelBtnAction !== "undefined") {
                error?.cancelBtnAction()
              }
              store.dispatch(setErrorMsg({title: null}))
          }}
      /> 
    </BrowserRouter>
  );
}

export default App;
