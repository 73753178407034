import React, { ForwardRefRenderFunction } from 'react';

interface IProps {
  show: boolean;
  closeBtn?: boolean;
  icon?: JSX.Element | null | undefined;
  title: string | null | undefined;
  errorMsg?: string | JSX.Element | null;

  acceptBtnTitle?: string | JSX.Element;
  acceptBtnOnClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  cancelBtnTitle?: string | JSX.Element | null;
  cancelBtnOnClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Alert: ForwardRefRenderFunction<HTMLInputElement, IProps> = 
          ({ show, closeBtn, title, errorMsg,acceptBtnTitle, 
            acceptBtnOnClick, cancelBtnTitle, cancelBtnOnClick, icon, ...otherProps }) => {
  return (
    <div className={"custom-alert" + (show ? ' show-modal' : '')}>
      <div className="modal-content">
          {closeBtn && (<span className="close-button">×</span>)}
          {icon && <div className="">
              {icon}
          </div>}
          <h1 className="title">{title}</h1>
          { errorMsg !== null && (<p className="description">{errorMsg}</p>)}
          <div className="modal-footer">
              {acceptBtnTitle && <button type="button" className="btn btn-warning btn-lg" onClick={acceptBtnOnClick}>{acceptBtnTitle}</button>}
              {cancelBtnTitle && <button type="button" className="btn btn-secondary btn-lg" onClick={cancelBtnOnClick}>{cancelBtnTitle}</button>}
          </div>
      </div>
  </div>
  );
}

