import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ErrorObj {
    icon?: JSX.Element | null,
    title: string | null,
    message?: string | JSX.Element | null,
    acceptBtnTitle?: string | JSX.Element | null,
    acceptBtnAction?: () => void | null | undefined,
    cancelBtnTitle?: string | JSX.Element | null,
    cancelBtnAction?: () => void | null | undefined,
}

export interface Settings {
    loading: boolean,
    error?: ErrorObj | null,
}

const initialState: Settings = {
    loading: false,
    error: {
        title: null, 
        message: null
    }
}

const settingsSlice = createSlice({
    initialState,
    name: "settings",
    reducers: {
        setLoadingSpinner(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setErrorMsg(state, action: PayloadAction<ErrorObj | null>) {
            state.error = action.payload;
        }
    }
});


export const { setLoadingSpinner, setErrorMsg } = settingsSlice.actions;
export default settingsSlice.reducer;