import API from '../../../../hooks/api';
import { userLoginData } from './ScreenHelper';

export const checkDataAPI = async (
        data: userLoginData,
        callBack: (success: boolean, errorMsg: string | null) => void = () => {}
    ) => {
    await API.get("/connect/merchant/password/reset/submit", {params: data})
    .then((res) => {
        res.data.result === "true" ? callBack(true, null) : callBack(false, res.data.result);
    }).catch((err) => {
        callBack(false, err);
    });
}
