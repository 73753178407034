import React from 'react';
import { Main } from '../routers/Main.router';
import { Sidebar } from './Sidebar/Sidebar';
import { RightSidebar } from './RightSidebar/RightSidebar';
import { Navbar } from './Navbar/Navbar';
import { Footer } from './Footer/Footer';
import { Auth } from '../routers/Auth.router';

export const AuthLayout: React.FC = () => {
    return (
        <> 
            <main className="main-content  mt-0">
                <Auth/>
            </main>
        </>
    );
}

