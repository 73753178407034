
export const getYearDiffWithMonth = (startDate: Date, endDate: Date) => {
    const ms = endDate.getTime() - startDate.getTime();
  
    const date = new Date(ms);
    return Math.abs(date.getUTCFullYear() - 1970);
}
  
export const parseJwt =  (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export const emailValidation = (email: string): boolean => {
    if(/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(email)) {
        return true;
    }
    return false;
}
